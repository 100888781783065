// import React, { useState } from 'react';
// import { Container, Typography, TextField, Button, makeStyles } from '@material-ui/core';
// import { Link, useNavigate } from 'react-router-dom';

// const useStyles = makeStyles((theme) => ({
//   form: {
//     maxWidth: '400px',
//     margin: '0 auto',
//     marginTop: theme.spacing(8),
//     padding: theme.spacing(4),
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     border: '1px solid #ccc',
//     borderRadius: theme.spacing(1),
//     boxShadow: theme.shadows[2],
//   },
//   textField: {
//     marginBottom: theme.spacing(2),
//   },
//   button: {
//     marginTop: theme.spacing(2),
//   },
// }));

// const Login = () => {
//   const classes = useStyles();
//   const [username, setUsername] = useState('');
//   const [password, setPassword] = useState('');
//   const navigate = useNavigate();
//   const handleSubmit = (event) => {
//     event.preventDefault();
//     navigate('/home')
//     console.log('Login submitted:', { username, password });

//   };

//   return (
//     <Container>

//       <form className={classes.form} onSubmit={handleSubmit}>
//         <Typography variant="h4" gutterBottom>
//           Login
//         </Typography>
//         <TextField
//           className={classes.textField}
//           label="Username"
//           value={username}
//           onChange={(e) => setUsername(e.target.value)}
//           fullWidth
//           required
//         />
//         <TextField
//           className={classes.textField}
//           label="Password"
//           type="password"
//           value={password}
//           onChange={(e) => setPassword(e.target.value)}
//           fullWidth
//           required
//         />
//         <Button
//           className={classes.button}
//           type="submit"
//           variant="contained"
//           color="primary"
//           fullWidth
//         >
//           Login
//         </Button>
//         <Typography variant="body2" style={{ marginTop: '16px' }}>
//           Don't have an account? <Link to="/signup">Sign up</Link>
//         </Typography>
//       </form>
//     </Container>
//   );
// };

// export default Login;
import React, { useState } from 'react';
import { Container, Typography, TextField, Button, makeStyles } from '@material-ui/core';
import { Link, useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  form: {
    maxWidth: '400px',
    margin: '0 auto',
    marginTop: theme.spacing(8),
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '1px solid #ccc',
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[2],
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

const Login = () => {
  const classes = useStyles();
  const [username, setUsername] = useState('admin@gmail.com');
  const [password, setPassword] = useState('1234');
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate('/patient');
    console.log('Login submitted:', { username, password });
  };

  return (
    <Container>
      <form className={classes.form} onSubmit={handleSubmit}>
        <Typography variant="h4" gutterBottom>
          Login
        </Typography>
        <TextField
          className={classes.textField}
          label="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          fullWidth
          required
        />
        <TextField
          className={classes.textField}
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          required
        />
        <Button
          className={classes.button}
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
        >
          Login
        </Button>
        <Typography variant="body2" style={{ marginTop: '16px' }}>
          Don't have an account? <Link to="/signup">Sign up</Link>
        </Typography>
      </form>
    </Container>
  );
};

export default Login;
