import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Button, CssBaseline, Drawer, Divider, InputBase, Menu, MenuItem } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Sidebar, Menu as ProMenu, MenuItem as ProMenuItem, SubMenu, useProSidebar, ProSidebarProvider } from 'react-pro-sidebar';
import { makeStyles } from '@material-ui/core/styles';
import PieChartIcon from '@material-ui/icons/PieChart';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import DescriptionIcon from '@material-ui/icons/Description';
import EventIcon from '@material-ui/icons/Event';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MailIcon from '@material-ui/icons/Mail';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import { FormatListBulleted, GroupAdd, People, VerifiedUser } from '@material-ui/icons';
import AddCardIcon from '@mui/icons-material/AddCard';
const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 55,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    position: 'relative',
    width: 240,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    marginTop: '65px',
    width: 240,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    marginTop: '65px',
    // width:'74px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 8,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8) + 1,
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginLeft: 55,
  },
}));

const Layout = ({ children }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const [notificationsAnchorEl, setNotificationsAnchorEl] = useState(null);
  const [messagesAnchorEl, setMessagesAnchorEl] = useState(null);

  const handleProfileMenuOpen = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleNotificationsMenuOpen = (event) => {
    setNotificationsAnchorEl(event.currentTarget);
  };

  const handleMessagesMenuOpen = (event) => {
    setMessagesAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setProfileAnchorEl(null);
    setNotificationsAnchorEl(null);
    setMessagesAnchorEl(null);
  };

  return (
    <ProSidebarProvider>
      <div className={classes.root}>
        <CssBaseline />
        {/* Header */}
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              FLUX
            </Typography>

          </Toolbar>
        </AppBar>
        {/* Sidebar */}
        <Drawer
          variant="permanent"
          className={`${classes.drawer} ${open ? classes.drawerOpen : classes.drawerClose}`}
          classes={{
            paper: open ? classes.drawerOpen : classes.drawerClose,
          }}
        >
          <Sidebar>
            <ProMenu iconShape="circle">
              {/* <SubMenu icon={<PieChartIcon />} label="Charts">
                <ProMenuItem icon={<PieChartIcon />}>Pie charts</ProMenuItem>
                <ProMenuItem icon={<ShowChartIcon />}>Line charts</ProMenuItem>
              </SubMenu> */}
              <Divider />
              <SubMenu icon={<People />} label="Patient">
                <Link to="/add-patient" style={{ color: 'black', textDecoration: 'none' }}>
                  <ProMenuItem icon={<GroupAdd />}>Add Patient</ProMenuItem>
                </Link>
                <Link to='/Patient' style={{ color: 'black', textDecoration: 'none' }}>
                  <ProMenuItem icon={<FormatListBulleted />}>List of Patient</ProMenuItem>
                </Link>
              </SubMenu>
              <SubMenu icon={<AddCardIcon />} label="ABHA">
                <Link to="/download-abha" style={{ color: 'black', textDecoration: 'none' }}>
                  <ProMenuItem icon={<AddCardIcon />}>Download ABHA Card</ProMenuItem>
                </Link>
                <Link to="/verify-abha" style={{ color: 'black', textDecoration: 'none' }}>
                  <ProMenuItem icon={<AddCardIcon />}>ABHA Verification</ProMenuItem>
                </Link>
                <Link to="/verify-abha-otp" style={{ color: 'black', textDecoration: 'none' }}>
                  <ProMenuItem icon={<AddCardIcon />}>ABHA Verification by OTP</ProMenuItem>
                </Link>
                <Link to="/create-abha" style={{ color: 'black', textDecoration: 'none' }}>
                  <ProMenuItem icon={<AddCardIcon />}>ABHA Verification by Aadhar OTP</ProMenuItem>
                </Link>
                <Link to="/scan-abha" style={{ color: 'black', textDecoration: 'none' }}>
                  <ProMenuItem icon={<AddCardIcon />}>Scan ABHA</ProMenuItem>
                </Link>
              </SubMenu>

            </ProMenu>
          </Sidebar>
        </Drawer>
        {/* Main content area */}
        <main className={classes.content}>
          {children}
        </main>
        <Footer />
      </div>
    </ProSidebarProvider>
  );
};

export default Layout;
