import React, { useState } from 'react';
import Layout from '../Layout/Layout';
import { Button, Container, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { baseUrl } from '../Redux/Slice/Aadhar';
import Swal from 'sweetalert2';

const useStyles = makeStyles((theme) => ({
    formContainer: {
        padding: theme.spacing(4),
        backgroundColor: '#f5f5f5',
        borderRadius: '8px',
    },
    formField: {
        margin: theme.spacing(2, 0),
    },
    submitButton: {
        marginTop: theme.spacing(4),
        backgroundColor: '#007bff',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#0056b3',
        },
    },
    formTitle: {
        marginBottom: theme.spacing(2),
        color: '#007bff',
    },
}));

const VerifyAbha = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [AbhaNumber, setAbhaNumber] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate ABHA address
        if (!AbhaNumber.endsWith('@sbx')) {
            toast.error('ABHA address must end with @sbx.');
            return;
        }

        const formData = new FormData();
        formData.append('AbhaAddress', AbhaNumber);

        try {
            const response = await fetch(`${baseUrl}SearchAbha`, {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();
            console.log(data.status);
            if (data && data.status === "ACTIVE") {
                setAbhaNumber('');
                // Show SweetAlert popup
                Swal.fire({
                    title: 'Success!',
                    html: `Card details Verified<br>Name: ${data.fullName}<br>Mobile: ${data.mobile}<br>Abha Address: ${data.abhaAddress}<br>Status: ${data.status}<br>Abha Number: ${data.healthIdNumber}`,
                    icon: 'success',
                    confirmButtonText: 'OK',
                    customClass: {
                        confirmButton: 'swal-confirm-button',
                    },
                });
            } else {
                toast.error(data.message);
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('An error occurred. Please try again.');
        }
    };

    return (
        <Layout>
            <Container maxWidth="lg">
                <div>
                    <Typography variant="h6" className={classes.formTitle}>
                        ABHA Verification
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="ABHA Address"
                                name="abhaAddress" // Change to a more appropriate name
                                value={AbhaNumber}
                                onChange={(event) => {
                                    setAbhaNumber(event.target.value);
                                }}
                            />
                        </Grid>
                        <br/>

                        <Typography >
                            Make sure your ABHA address is formatted properly as @sbx for seamless processing.
                        </Typography>
                        <Button
                            type="submit"
                            variant="contained"
                            className={classes.submitButton}
                        >
                            Submit
                        </Button>
                    </form>
                </div>
            </Container>
        </Layout>
    );
};

export default VerifyAbha;
