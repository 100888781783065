import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../Layout/Layout';
import { Container, makeStyles, TextField, Typography, CircularProgress, Grid } from '@material-ui/core';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
    formContainer: {
        padding: theme.spacing(4),
        backgroundColor: '#f5f5f5',
        borderRadius: '8px',
        position: 'relative',
    },
    formTitle: {
        marginBottom: theme.spacing(2),
        color: '#007bff',
    },
    loader: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    warningMessage: {
        textAlign: 'center',
        marginTop: theme.spacing(2),
        color: 'red',
    },
    Message: {
        textAlign: 'left',
        marginTop: theme.spacing(2),
        color: 'black',
    },
}));

const Scan = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [scannedData, setScannedData] = useState({
        hidn: '',
        hid: '',
        name: '',
        gender: '',
        statelgd: '',
        distlgd: '',
        dob: '',
        district_name: '',
        mobile: '',
        address: '',
        state_name: '',
    });
    const [loading, setLoading] = useState(false);
    const [errorShown, setErrorShown] = useState(false);
    const [isDataReady, setIsDataReady] = useState(false);
    const [loader, setLoader] = useState(true);
    const inputRef = useRef(null);

    const handleScanInput = (event) => {
        setLoader(false);
        const data = event.target.value;
        if (data) {
            setLoading(true);
            setErrorShown(false);
            try {
                const parsedData = JSON.parse(data);
                setScannedData(parsedData);
                toast.success('Data scanned successfully!');
                event.target.value = ''; // Clear input after processing
                setIsDataReady(true);
                setLoader(true);
            } catch (error) {
                if (!errorShown) {
                    // toast.error('Error parsing scanned data. Please ensure it is in JSON format.');
                    setErrorShown(true);
                }
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        const input = inputRef.current;
        input.addEventListener('input', handleScanInput);
        input.focus();

        return () => {
            input.removeEventListener('input', handleScanInput);
        };
    }, [errorShown]);

    // Handle navigation attempts
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (loading) {
                event.preventDefault();
                event.returnValue = ''; // Necessary for some browsers to show a prompt
            }
        };

        const handlePopState = (event) => {
            if (loading) {
                toast.info('Please complete the scan process before navigating away.');
                navigate(0); // Reloads the current page to prevent navigation
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('popstate', handlePopState);
        };
    }, [loading, navigate]);

    return (
        <Layout>
            <Container maxWidth="lg" className={classes.formContainer}>
                <Typography variant="h6" className={classes.formTitle}>
                    Scan ABHA Card
                </Typography>
           
                <TextField
                    label="ABHA Information"
                    variant="outlined"
                     ref={inputRef}
                    type="text" autoFocus

                    InputProps={{
                        readOnly: true,
                    }}
                />

<Typography className={classes.Message}>
                    Use a barcode reader to scan the QR code on the Abha Card, and you'll instantly access all relevant information.
                </Typography>
                {/* <input
                    ref={inputRef}
                    type="text"
                    // style={{ position: 'absolute', opacity: 0, pointerEvents: 'none' }}
                    autoFocus
                /> */}

                {!loader && (
                    <>
                        <div className={classes.loader}>
                            <CircularProgress />
                        </div>
                        <Typography className={classes.warningMessage}>
                            If you switch pages or browsers, you'll have to begin from the beginning.
                        </Typography>
                    </>
                )}

                {isDataReady && (
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={4}>
                            <TextField
                                label="HIDN"
                                variant="outlined"
                                fullWidth
                                name="hidn"
                                value={scannedData.hidn}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <TextField
                                label="HID"
                                variant="outlined"
                                fullWidth
                                name="hid"
                                value={scannedData.hid}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <TextField
                                label="Name"
                                variant="outlined"
                                fullWidth
                                name="name"
                                value={scannedData.name}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <TextField
                                label="Gender"
                                variant="outlined"
                                fullWidth
                                name="gender"
                                value={scannedData.gender}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <TextField
                                label="State Lgd"
                                variant="outlined"
                                fullWidth
                                name="statelgd"
                                value={scannedData.statelgd}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <TextField
                                label="District Lgd"
                                variant="outlined"
                                fullWidth
                                name="distlgd"
                                value={scannedData.distlgd}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <TextField
                                label="DOB"
                                variant="outlined"
                                fullWidth
                                name="dob"
                                value={scannedData.dob}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <TextField
                                label="District Name"
                                variant="outlined"
                                fullWidth
                                name="district_name"
                                value={scannedData.district_name}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <TextField
                                label="Mobile"
                                variant="outlined"
                                fullWidth
                                name="mobile"
                                value={scannedData.mobile}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <TextField
                                label="Address"
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={3}
                                name="address"
                                value={scannedData.address}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <TextField
                                label="State Name"
                                variant="outlined"
                                fullWidth
                                name="state_name"
                                value={scannedData.state_name}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                )}
            </Container>
        </Layout>
    );
}

export default Scan;
