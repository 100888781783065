import React from 'react';
import { Typography, AppBar, Toolbar } from '@material-ui/core';

const Footer = () => {
  return (
    <AppBar position="fixed" style={{ top: 'auto', bottom: 0, left: '63px' }}>
      <Toolbar>
        <Typography variant="body1" style={{ flexGrow: 1 }}>
          © 2024 Hospital Management System. All rights reserved.
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default Footer;
