import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
export const baseUrl = 'https://api.fluxemr.in/'
// export const baseUrl = 'http://127.0.0.1:8082/'
// export const baseUrl ='http://15.206.6.166/'

// API URL
const apiUrl = `${baseUrl}POST/request-otp-aadhar`;

// Async thunk to send Aadhar number and receive OTP
export const AadharPosts = createAsyncThunk(
  "posts/AadharPosts",
  async (formData) => {
    try {
      const response = await axios.post(apiUrl, formData);
      return response.data;
    } catch (error) {
      console.error("Error sending Aadhar number:", error);
      throw error;
    }
  }
);

// Slice to manage posts state
const AadharSlice = createSlice({
  name: "posts",
  initialState: {
    posts: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AadharPosts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(AadharPosts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.posts = action.payload;
      })
      .addCase(AadharPosts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default AadharSlice.reducer;
